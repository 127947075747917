import gsap from "gsap";

export function slide() {
  /*
スライド
*/
  var element1 = document.getElementById("slideItem1");
  var element2 = document.getElementById("slideItem2");
  var element3 = document.getElementById("slideItem3");

  gsap.set(element2, {
    autoAlpha: 0,
  });
  gsap.set(element3, {
    autoAlpha: 0,
  });

  const slideTL1 = gsap.timeline({ repeat: -1 });
  slideTL1
    .to(element1, { duration: 3.75 })
    .to(element1, {
      autoAlpha: 0,
      duration: 1.25,
      ease: "power3.easeOut",
    })
    .to(
      element2,
      {
        autoAlpha: 1,
        duration: 1.25,
        ease: "power3.easeOut",
      },
      "-=1.25"
    )
    .to(element2, { duration: 3.75 })
    .to(element2, {
      autoAlpha: 0,
      duration: 1.25,
      ease: "power3.easeOut",
    })
    .to(
      element3,
      {
        autoAlpha: 1,
        duration: 1.25,
        ease: "power3.easeOut",
      },
      "-=1.25"
    )
    .to(element3, { duration: 3.75 })
    .to(element3, {
      autoAlpha: 0,
      duration: 1.25,
      ease: "power3.easeOut",
    })
    .to(
      element1,
      {
        autoAlpha: 1,
        duration: 1.25,
        ease: "power3.easeOut",
      },
      "-=1.25"
    );
}
