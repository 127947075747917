import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { resize } from "./resize";

export function lp() {
  gsap.registerPlugin(ScrollTrigger);

  const mediaQueryList = window.matchMedia("(min-width: 1200px)");

  const listener = (event) => {
    // リサイズ時に行う処理
    if (event.matches) {
      gloNavPC();
    } else {
      gloNavSP();
    }
  };

  // リスナー登録
  // mediaQueryList.addListener(listener); // @deprecated
  mediaQueryList.addEventListener("change", listener);

  // 初期化処理
  listener(mediaQueryList);
}

/*
  PC版のグロナビ動作
  */
const gloNavPC = function () {
  /*
  グロナビ
  */

  var nav = document.querySelector("nav");
  var ico = document.getElementsByClassName("navIco");

  // gsap.set(nav, {
  //   autoAlpha: 0,
  //   y: 0,
  // });

  // スクロールされたら表示
  // window.addEventListener("scroll", scroll_event);
  // function scroll_event() {
  //   if (window.pageYOffset > window.innerHeight) {
  //     gsap.to(nav, {
  //       autoAlpha: 1,
  //       duration: 0.1,
  //     });
  //   } else if (window.pageYOffset < window.innerHeight) {
  //     gsap.to(nav, {
  //       autoAlpha: 0,
  //       duration: 0.1,
  //     });
  //   }
  // }

  /*グロナビのアイコンの移動*/

  gsap.fromTo(
    ico,
    {
      y: 0,
    },
    {
      y: 37,
      scrollTrigger: {
        trigger: "#nav2",
        start: "top bottom-=100",
        end: "top bottom-=200",
        scrub: true,
      },
    }
  );

  gsap.fromTo(
    ico,
    {
      y: 37,
    },
    {
      y: 74,
      scrollTrigger: {
        trigger: "#nav3",
        start: "top bottom-=100",
        end: "top bottom-=200",
        scrub: true,
      },
    }
  );

  gsap.fromTo(
    ico,
    {
      y: 74,
    },
    {
      y: 111,
      scrollTrigger: {
        trigger: "#nav4",
        start: "top bottom-=100",
        end: "top bottom-=200",
        scrub: true,
      },
    }
  );

  gsap.fromTo(
    ico,
    {
      y: 111,
    },
    {
      y: 148,
      scrollTrigger: {
        trigger: "#nav5",
        start: "top bottom-=100",
        end: "top bottom-=200",
        scrub: true,
      },
    }
  );

  // gsap.fromTo(
  //   ico,
  //   {
  //     y: 148,
  //   },
  //   {
  //     y: 185,
  //     scrollTrigger: {
  //       trigger: "#nav6",
  //       start: "top bottom-=100",
  //       end: "top bottom-=200",
  //       scrub: true,
  //     },
  //   }
  // );

  gsap.set(ico, {
    y: 0,
  });

};

/*
  SP版のグロナビ動作
  */
const gloNavSP = function () {
  let body = document.getElementsByTagName("body");
  let header = document.querySelector("header");
  let openBtn = document.getElementsByClassName("openBtn");

  gsap.set(header, {
    autoAlpha: 0,
  });

  function handle(event) {
    event.preventDefault();
  }

  openBtn[0].addEventListener("click", function () {
    if (header.classList.contains("opened")) {
      header.classList.remove("opened");
      closeBarAction();
      gsap.to(header, {
        autoAlpha: 0,
      });
      document.removeEventListener("touchmove", handle, { passive: false });
      document.removeEventListener("mousewheel", handle, { passive: false });
    } else {
      resize();
      header.classList.add("opened");
      openBarAction();
      openNavAction();
      gsap.to(header, {
        autoAlpha: 1,
      });
      document.addEventListener("touchmove", handle, { passive: false });
      document.addEventListener("mousewheel", handle, { passive: false });
    }
  });

  //アンカーボタンをクリックしたらグロナビを閉じる
  var navBtn = document.querySelectorAll("nav ul li");
  for (var i = 0; i < navBtn.length; i++) {
    navBtn[i].addEventListener("click", function () {
      header.classList.remove("opened");
      closeBarAction();
      gsap.to(header, {
        autoAlpha: 0,
      });
      document.removeEventListener("touchmove", handle, { passive: false });
      document.removeEventListener("mousewheel", handle, { passive: false });
    });
  }
};

/*グロナビの動作*/
const openNavAction = function () {};

/*ハンバーガーボタンの動作*/
const openBarAction = function () {
  gsap.to(".one", {
    rotate: 45,
    duration: 0.3,
    y: 10,
    ease: "Expo.easeInOut",
  });
  gsap.to(".three", {
    rotate: -45,
    duration: 0.3,
    y: -6,
    ease: "Expo.easeInOut",
  });
  gsap.to(".two", {
    autoAlpha: 0,
    duration: 0.3,
    ease: "Expo.easeInOut",
  });
};

const closeBarAction = function () {
  gsap.to(".one", {
    rotate: 0,
    duration: 0.3,
    y: 0,
    ease: "B.easeInOut",
  });
  gsap.to(".three", {
    rotate: 0,
    duration: 0.3,
    y: 0,
    ease: "Expo.easeInOut",
  });
  gsap.to(".two", {
    autoAlpha: 1,
    duration: 0.3,
    ease: "Expo.easeInOut",
  });
};
